import React, { useEffect, useState } from "react";
import {
  StyledHeading,
  StyledWrap,
  ListItem,
  ItemHeader,
  StyledHeadingg,
  ItemContent,
  Content,
  QuestionList,
  SectionWrap,
  QuestionsPart, 
  StyledDescription
} from "./styles";
import { SectionSettings } from "../../interfaces/sanity";
import { useLocation } from "@reach/router";
import useToggle from "src/hooks/useToggle";
import { useMediaQuery } from "react-responsive";
import Moon from "src/images/moon.png";

export const size = {
  phone: 480,
};
interface Props {
  data: {
    sectionSettings: SectionSettings;
    title?: string;
    description?: string;
    replaceIcon?: boolean;
    useMoonIcon?: boolean;
    list: Array<{
      _key: string;
      id?: string;
      question: string;
      answer: string;
    }>;
    generalFooter: object;
  };
  context?: {
    reviews: object;
  };
  isSection?: boolean;
}

const PDPFAQSection = ({ data, context, plusSign, isSection = true }: Props) => {
  const { title, list, sectionSettings, description, useMoonIcon } = data;

  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState<number>(-1);

  const isMobile = useMediaQuery({ maxWidth: size.phone });

  useEffect(() => {
    if (!sectionSettings?.id) return;
    const hash = location.hash;

    const index = list.findIndex((item) => `#${item.id}` === hash);

    setActiveIndex(index);
  }, [location]);



  return (
    <StyledWrap isSection={isSection}>

      <SectionWrap>
        {title &&
          <StyledHeading>{title}</StyledHeading>}
          {description && <StyledDescription>{description}</StyledDescription>}

      </SectionWrap>
      <QuestionsPart>

        <QuestionList>
          {list?.map((item, i) => {

            const [open, toggleOpen] = useToggle();

            useEffect(() => {
              toggleOpen(i === activeIndex);
            }, [i === activeIndex]);

            return (
              <ListItem open={open} onClick={toggleOpen} id={item?.id}>
                <ItemHeader plusSign={plusSign}>
                  {useMoonIcon ? <img src={Moon} style={{width: "3.5rem"}}/> : <span className="plus-sign">{open ? "-" : "+"}</span>}
                  <StyledHeadingg open={open}>{item?.question}</StyledHeadingg>
                </ItemHeader>
                {item?.answer && (
                  <ItemContent open={open}>
                    <Content blocks={item?.answer} />
                  </ItemContent>
                )}
              </ListItem>
            )
          })}
        </QuestionList>
      </QuestionsPart>
    </StyledWrap>
  );
};

export default PDPFAQSection;