import styled, { css } from "styled-components";
import { Heading, Paragraph } from "src/components/Text";
import Icon from "../Common/Icon";
import BlockContent from "../../components/BlockContent";

interface ListItemProps {
  plusSign?: boolean;
  notificationBar: boolean;
}


export const ListItem = styled.li<ListItemProps>`
  position: relative;
  cursor: pointer;
  list-style-type: none;
  padding: 1.3rem 2.2rem 1.3rem;
  margin: 0;
  scroll-margin-top: ${({ notificationBar }) =>
    notificationBar ? "9rem" : "6.3rem"};

  @media ${({ theme }) => theme.device.tablet} {
    padding: 1.3rem 0;
    scroll-margin-top: ${({ notificationBar }) =>
      notificationBar ? "17.5rem" : "12.7rem"};
  }

  &:last-child:after {
    content: none;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.1rem;
    background-color: ${({ theme }) => theme.colors.font_black};
    opacity: 0.1;
  }
`;

export const StyledHeading = styled(Heading)`
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.font_black};
`;

export const StyledIcon = styled(Icon)`
  margin-right: 1.5rem;
  max-width: 4.6rem;
  width: 100%;
  height: 4.6rem;
  align-self: flex-start;
`;

export const ItemHeader = styled.div<ListItemProps>`
  display: flex;
  flex-direction: ${({ plusSign }) => plusSign ? 'row-reverse' : 'row'};
  justify-content: ${({ plusSign }) => plusSign ? 'space-between': 'left'};
  align-items: center;
`;

export const ItemContent = styled.div`
  padding: ${({ open }) => (open ? "3.1rem 0 0.5rem 0.3rem" : "0")};
  overflow: hidden;
  max-height: ${({ open }) => (open ? "initial" : "0")};

  @media ${({ theme }) => theme.device.tablet} {
    padding: ${({ open }) => (open ? "2.2rem 0 1.9rem 11.1rem" : "0")};
  }
`;

export const Content = styled(BlockContent)`
  font-size: 1.4rem;
  line-height: 2.2rem;
  font-family: 'Effra-Regular';
  color: ${({ theme }) => theme.colors.font_black};
  font-weight: 400;
  max-width: 100%;
`;

export const ContentImage = styled.img`
  max-width: 5rem;
  margin-bottom: 1rem;
`;
