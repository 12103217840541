import styled from "styled-components";
import Wrap from "src/components/Wrap";
import { SectionHeading } from "src/components/Text";
import Slider from "react-slick";
import Image from "src/components/Common/ImageFluid";
import ArrowIcon from "src/images/arrow-left.svg";
import WhiteArrow from "src/images/newArrow.svg";
import Button from "src/components/Common/Button";
import { Link } from "gatsby";

export const StyledWrap = styled(Wrap)<{ usePinkArrows?: boolean }>`
  padding: 4.938rem 2rem
    ${({ usePinkArrows }) => (usePinkArrows ? "8rem" : "4.5rem")} 2rem;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 4.938rem 7rem
      ${({ usePinkArrows }) => (usePinkArrows ? "9rem" : "0rem")} 8.9rem;
  }

  @media ${({ theme }) => theme.device.phone} {
    padding: 4.313rem 3.063rem
      ${({ usePinkArrows }) => (usePinkArrows ? "8rem" : "3rem")};
  }

  &.variant_A {
    display: none;
  }
`;
export const Heading = styled(SectionHeading)`
  font-family: "Effra-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 53px;
  text-align: center;
  margin-bottom: 2.75rem;

  @media ${({ theme }) => theme.device.phone} {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 3.063rem;
  }
`;

export const CarouselContainer = styled.div`
  padding: 3rem 0rem;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 0rem 0rem 3rem 0rem;
  }
  @media ${({ theme }) => theme.device.tablet} {
    padding: 3em 0rem 7rem 0rem;
  }
  @media ${({ theme }) => theme.device.laptop} {
    padding: 3em 0rem 7rem 0rem;
  }
`;

export const SlideNumber = styled.button``;

export const ReviewQuote = styled.p`
  font-family: "Effra-Regular";
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: left;
`;

export const StyledImage = styled(Image)`
  // height: 225px;
`;

export const ImageContainer = styled.div`
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const Reviewer = styled.div`
  margin-top: 1.3rem !important;
`;

export const ReviewerName = styled.p`
  font-family: "Effra-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 0.625rem !important;
  letter-spacing: -0.3px;
  color: ${({ theme }) => theme.colors.font_pink};
`;

export const ReviewerPosition = styled.p`
  font-family: "Effra-Regular";
  font-size: 14px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: -0.30000001192092896px;
  text-align: left;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.5rem 2rem !important;
`;

export const Slide = styled.div`
  width: 100%;
  // background: #ffffff;
  border-radius: 10px;
`;

export const StyledSlider = styled(Slider)`
  .slick-track {
    display: flex;
    justify-content: center;
  }

  .slick-arrow,
  .slick-next {
    opacity: 1;
  }

  .slick-arrow {
    width: 3rem;
    height: 3rem;
    background-image: url(${ArrowIcon});
    background-repeat: none;
    background-size: cover;
    z-index: 1;

    &:before {
      content: none;
    }

    &.slick-next {
      right: -6.5rem;
    }

    &.slick-prev {
      transform: translateY(-1.6rem) rotate(180deg);
      left: -6.5rem;
    }

    @media ${({ theme }) => theme.device.phone} {
      &.slick-next {
        right: -2.3rem;
      }
      &.slick-prev {
        left: -2.4rem;
      }
    }

    @media ${({ theme }) => theme.device.tablet} {
      &.slick-next {
        right: -6rem;
      }
    }
  }

  ul.slick-dots {
    top: 2rem;
    left: 2rem;
    bottom: unset;
    width: auto;

    @media ${({ theme }) => theme.device.tablet} {
      top: 3rem;
      left: 4.2rem;
    }

    li {
      margin: 0 0.9rem 0 0;
      width: auto;
      height: auto;

      &:last-child {
        margin: 0;
      }

      ${SlideNumber} {
        font-size: 0;
        width: 0.8rem;
        height: 0.8rem;
        border: 0;
        border-radius: 50%;

        &::before {
          content: none;
        }
      }
    }
  }
  .slick-slide {
    padding: 0 1rem;
  }

  ul.slick-dots {
    top: 100% !important;
    position: absolute;
    left: 47% !important;
    @media ${({ theme }) => theme.device.phone} {
      left: 40% !important;
    }
    margin: 2rem 0;
  }

  .slick-active button {
    font-size: 0rem;
    height: 0.5rem;
    width: 0.5rem;
  }

  li button {
    background: #e5e5e5;
    border: 0 !important;
  }

  .slick-active button {
    background: #878491;
    border: 0 !important;
  }
`;
export const PinkArrowsSlider = styled(Slider)`
 .slick-arrow,
  .slick-next {
    opacity: 1;
  }

  .slick-arrow {
    width: 3rem;
    height: 3rem;
    background-image: url(${WhiteArrow});
    background-size: cover;
    background-size: unset;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    z-index: 1;

    &:before {
      content: none;
    }

    &.slick-next {
      transform: translateY(0) rotate(180deg);
      right: 24.5%;
      top: 100%;
      background-color: #F58E8A;
      width: 40px;
      height: 40px;
      border-radius: 4px;
      margin-top: 2%;
    }

    &.slick-prev {
        left: 24%;
        top: 100%;
        transform: translateX(0);
        background-color: #F58E8A;
        width: 40px;
        height: 40px;
        border-radius: 4px;
        margin-top: 2%;
    }

    @media ${({ theme }) => theme.device.tablet} {
      &.slick-next {
        right:  22.5%
        margin-top: 4%;
      }
  
      &.slick-prev {
        left: 22%;
      }
    }

    @media ${({ theme }) => theme.device.phone} {
      &.slick-next {
        right: 5%;
        top: 100%;
        background-color: #F58E8A;
        width: 35px;
        height: 35px;
        border-radius: 4px;
      }
  
      &.slick-prev {
        // left: -6.5rem;
          left: 5%;
          top: 100%;
          transform: translateX(0);
          background-color: #F58E8A;
          width: 35px;
          height: 35px;
          border-radius: 4px;
      }
    }
    @media ${({ theme }) => theme.device.smPhone} {
      &.slick-next {
        right: 0%;
        top: 100%;
        background-color: #F58E8A;
        width: 35px;
        height: 35px;
        border-radius: 4px;
        margin-top: 5%;
      }
  
      &.slick-prev {
        // left: -6.5rem;
          left: 0%;
          top: 100%;
          transform: translateX(0);
          background-color: #F58E8A;
          width: 35px;
          height: 35px;
          border-radius: 4px;
          margin-top: 5%;
      }
    }
  }
  ul.slick-dots {
  @media ${({ theme }) => theme.device.laptop} {
        margin-top: 3% !important;
      }


      @media ${({ theme }) => theme.device.phone} {
        margin-top: 5% !important;
      }
      @media ${({ theme }) => theme.device.smPhone} {
        margin-top: 8% !important;
      }
      top: 100% !important;
      position: absolute;
      margin-top: 4% !important;
      margin: 2rem 0;
      li {
        margin: 0 2.5rem 0 0;
        width: auto;
        height: auto;
  
        &:last-child {
          margin: 0;
        }
  
        button {
          font-size: 0;
          width: 15px;
          height: 15px;
          border: 0;
          border-radius: 50%;

  
          &::before {
            content: none;
          }
        }

        @media ${({ theme }) => theme.device.phone} {
          margin: 0 0.7rem 0 0;
          button {
            width: 10px;
            height: 10px;
          }
          
        }
      }
    }
  
    .slick-active button {
      font-size: 0rem;
      height: 0.5rem;
      width: 0.5rem;
    }
  
    li button {
      background: #E5E5E5;
      border: 0 !important;
    }
  
    .slick-active button {
      background: #F58E8A;
      border: 0 !important;
    }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ButtonLink = styled(Link)``;

export const StyledButton = styled(Button)`
  background: linear-gradient(180deg, #e16e30 0%, rgba(225, 110, 48, 0) 100%),
    #f4b959;
  border-radius: 20px;
  margin-top: 3.438rem;
  font-family: "Effra-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  padding: 0 4.3rem;

  ${({ theme }) => theme.device.phone} {
    margin-top: 2.813rem;
  }
`;
