import React from "react";
import {
  StyledWrap,
  Heading,
  CarouselContainer,
  StyledSlider,
  Content,
  SlideNumber,
  ReviewQuote,
  Reviewer,
  ReviewerName,
  ReviewerPosition,
  StyledImage,
  StyledButton,
  ButtonContainer,
  ButtonLink,
  ImageContainer,
  PinkArrowsSlider
} from "./styles";
import { SanityImage, SanityColor } from "../../interfaces/sanity";
import { SanityButton } from "src/interfaces/common";
import { useMediaQuery } from "react-responsive";
import { size } from "src/components/Carousel";
import { Slide } from "src/components/TestimonialSlider/styles";

interface Props {
  data: {
    _key: string;
    _type: "carouselReviews";
    title?: string;
    reviews: [
      {
        image: SanityImage;
        quote: string;
        reviewerName?: string;
        reviewerPosition?: string;
      }
    ];
    button: SanityButton;
    sectionSettings: {
      backgroundColor: SanityColor;
      backgroundImage: SanityImage;
    };
    experiment: {
      variantA?: boolean;
      variantB?: boolean;
    };
    usePinkArrows?: boolean;
  };
}

const CarouselReviews = ({ data }: Props) => {
  const { title, reviews, button, usePinkArrows } = data;
  const isMobile = useMediaQuery({ maxWidth: size.phone });
  const isTablet = useMediaQuery({ maxWidth: size.tablet });

  const settings = {
    dots: true,
    arrows: !!usePinkArrows,
    speed: 500,
    infinite: false,
    slidesToShow: isMobile ? 1 : isTablet ? 2 : 3,
    slidesToScroll: 1,
    customPaging: (i: number) => <SlideNumber>{i + 1}</SlideNumber>
  };

  return (
    <StyledWrap
      id={`${data._type}-${data._key}`}
      className={
        data?.experiment?.variantA
          ? "variant_A"
          : data?.experiment?.variantB
          ? "variant_B"
          : ""
      }
      usePinkArrows={usePinkArrows}
    >
      {title && <Heading>{title}</Heading>}
      <CarouselContainer>
        {reviews &&
          (usePinkArrows ? (
            <PinkArrowsSlider {...settings}>
              {reviews.map(review => (
                <Slide key={review.quote}>
                  <ImageContainer>
                    <StyledImage imageRef={review.image} />
                  </ImageContainer>
                  <Content>
                    <ReviewQuote>{review?.quote}</ReviewQuote>
                    <Reviewer>
                      <ReviewerName>{review?.reviewerName}</ReviewerName>
                      <ReviewerPosition>
                        {review?.reviewerPosition}
                      </ReviewerPosition>
                    </Reviewer>
                  </Content>
                </Slide>
              ))}
            </PinkArrowsSlider>
          ) : (
            <StyledSlider {...settings}>
              {reviews.map(review => (
                <Slide key={review.quote}>
                  <ImageContainer>
                    <StyledImage imageRef={review.image} />
                  </ImageContainer>
                  <Content>
                    <ReviewQuote>{review?.quote}</ReviewQuote>
                    <Reviewer>
                      <ReviewerName>{review?.reviewerName}</ReviewerName>
                      <ReviewerPosition>
                        {review?.reviewerPosition}
                      </ReviewerPosition>
                    </Reviewer>
                  </Content>
                </Slide>
              ))}
            </StyledSlider>
          ))}
      </CarouselContainer>
      {button.enabled && (
        <ButtonContainer>
          <ButtonLink to={button?.link}>
            <StyledButton>{button.text}</StyledButton>
          </ButtonLink>
        </ButtonContainer>
      )}
    </StyledWrap>
  );
};

export default CarouselReviews;
