import React from "react";
import QuestionItem from "../QuestionItem";
import { List } from "./styles";

interface Props {
  list: Array<{
    _key: string;
    id?: string;
    question: string;
    answer: string;
  }>;
  activeIndex: number;
  context?: any;
  plusSign?: boolean;
  replaceIcon?:boolean;
  useMoonIcon?: boolean;
}

const QuestionList = ({ list, activeIndex, context, plusSign, replaceIcon, useMoonIcon }: Props) => {
  return (
    <List>
      {list?.map((item, i) => (
        <QuestionItem
          plusSign={plusSign}
          notificationBar={
            context?.notificationBar
              ? context.notificationBar[0].enabled
              : false
          }
          key={item._key}
          active={i === activeIndex}
          question={item.question}
          answer={item.answer}
          id={item.id}
          replaceIcon={replaceIcon}
          useMoonIcon={useMoonIcon}
        />
      ))}
    </List>
  );
};

export default QuestionList;
