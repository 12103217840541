import React, { useEffect } from "react";
import {
  ListItem,
  StyledHeading,
  StyledIcon,
  ItemHeader,
  ItemContent,
  Content,
  ContentImage,
} from "./styles";
import useToggle from "src/hooks/useToggle";
import LogoBlack from "src/images/groove.svg";
import NewGift from "src/images/newgift.svg";
import Moon from "src/images/moon.png";

interface Props {
  id?: string;
  active: boolean;
  question: string;
  answer: string;
  notificationBar?: boolean;
  plusSign?: boolean;
  replaceIcon?:boolean;
  useMoonIcon?: boolean;
}

const QuestionItem = ({
  question,
  answer,
  id,
  active,
  notificationBar,
  plusSign, 
  replaceIcon,
  useMoonIcon
}: Props) => {
  const [open, toggleOpen] = useToggle();

  useEffect(() => {
    toggleOpen(active);
  }, [active]);

  return (
    <ListItem onClick={toggleOpen} id={id} notificationBar={notificationBar}>
      <ItemHeader plusSign={plusSign}>
        {replaceIcon ? <img src={NewGift} style={{margin: "-3px 10px 0 0"}}/> :  
          useMoonIcon ? <img src={Moon} style={{margin: "-3px 15px 0 0", width: "3.5rem"}}/> : <>
          {
            plusSign ? (!open ? <StyledIcon name="QAplus" /> : <StyledIcon name="QAminus" />) : <StyledIcon name="question" />
          }
        </>
      }
        <StyledHeading>{question}</StyledHeading>
      </ItemHeader>
      {answer && (
        <ItemContent open={open}>
          <ContentImage src={LogoBlack} alt="Question"/>
          <Content blocks={answer} />
        </ItemContent>
      )}
    </ListItem>
  );
};

export default QuestionItem;
