import React, { useEffect, useState } from "react";
import {
  TrustpilotContainer,
  TrustpilotBox,
  Title,
  Subtitle,
  StyledImage,
  StyledButton,
  ImageButtonBox,
  LinkedText,
  StyledSubtitle,
  LinkedTextButton,
  ReviewsWidget,
  StarRating,
  OkendoContainer,
} from "./styles";
import Trustpilot from "src/images/trustpilot.svg";
import locale from "src/locales";

interface Props {
  productInfo: any;
}

const OkendoWidget = ({ productInfo }: Props) => {

  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    const loadScript = () => {
      const storeUrl =
        process.env.GATSBY_CURRENCY_SYMBOL === "$"
          ? "https://d3hw6dc1ow8pp2.cloudfront.net/reviewsWidget.min.js?shop=groovepillows.com"
          : "https://d3hw6dc1ow8pp2.cloudfront.net/reviewsWidget.min.js?shop=groovepillows.co.uk";

      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.src = storeUrl;

      const head = document.getElementsByTagName("head")[0];
      !scriptLoaded && head.appendChild(script);
    };

    loadScript();
    setScriptLoaded(true);
  }, []);

  const okendoSettings = {
    filtersEnabled: true,
    omitMicrodata: true,
    subscriberId:
      process.env.GATSBY_CURRENCY_SYMBOL === "$"
        ? "cabf925a-5864-41eb-b236-e293c7a2c889"
        : "265b33f8-0bd1-4b35-af35-9714f6d6fb00",
    widgetTemplateId: "default"
  };

  let product_id;

  if (productInfo?._type != "productsShopify" && productInfo?._type != undefined) {
    product_id = locale.imageRowSection.product_id_okendo
  } else {
    product_id = `shopify-${productInfo?.id ? productInfo?.id : productInfo?._id?.replace("products-", "")}`
  }

  return (
    <>
      <link
        href="https://d3hw6dc1ow8pp2.cloudfront.net/styles/main.min.css"
        rel="stylesheet"
        type="text/css"
      />
      <script type="application/json" id="oke-reviews-settings">
        {JSON.stringify(okendoSettings)}
      </script>
      <TrustpilotContainer id="truspilot-container">
        <Title>Customer Reviews</Title>
        {/* <TrustpilotBox>
          <Subtitle>Read our reviews on:</Subtitle>
          <ImageButtonBox>
            <img src={Trustpilot} alt="TP logo" />
            <LinkedTextButton>
              <LinkedText>1000+ reviews</LinkedText>
              <StyledButton to="https://www.trustpilot.com/review/easysleeper.co.uk">
                Read Now
              </StyledButton>
            </LinkedTextButton>
          </ImageButtonBox>
        </TrustpilotBox> */}
        <StyledSubtitle>
          Read reviews left on our website.
        </StyledSubtitle>
      </TrustpilotContainer>
      <OkendoContainer>
        <div data-oke-reviews-widget-holder data-oke-reviews-product-id={product_id}>
          <div data-oke-reviews-nav-bar></div>
          <StarRating id="oke-starSnippet" data-oke-reviews-product-listing-rating data-oke-reviews-scroll-target-id="drawer-container" data-oke-reviews-product-id={product_id}></StarRating>
          <ReviewsWidget data-oke-reviews-widget></ReviewsWidget>
          <div data-oke-reviews-qanda></div>
        </div>
      </OkendoContainer>
    </>
  );
};

export default OkendoWidget;
